import React from 'react';

export const EyeOnSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="512"
    height="512"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M460.465 264.803C419.15 338.996 340.798 385.085 256.027 385.085C171.201 385.085 92.8497 338.996 51.5337 264.803C48.3517 259.042 48.3517 252.952 51.5337 247.196C92.8497 173.004 171.201 126.92 256.027 126.92C340.798 126.92 419.149 173.004 460.465 247.196C463.703 252.952 463.703 259.042 460.465 264.803ZM486.857 232.486C440.219 148.719 351.772 96.6885 256.027 96.6885C160.227 96.6885 71.7797 148.719 25.1417 232.486C16.8017 247.432 16.8017 264.567 25.1417 279.502C71.7797 363.269 160.227 415.311 256.027 415.311C351.772 415.311 440.219 363.269 486.857 279.502C495.197 264.568 495.197 247.432 486.857 232.486ZM256.027 318.014C290.21 318.014 318.028 290.196 318.028 255.997C318.028 221.798 290.21 193.98 256.027 193.98C221.789 193.98 193.971 221.798 193.971 255.997C193.971 290.196 221.79 318.014 256.027 318.014ZM256.027 163.748C205.109 163.748 163.739 205.135 163.739 255.998C163.739 306.872 205.11 348.242 256.027 348.242C306.89 348.242 348.26 306.871 348.26 255.998C348.26 205.135 306.89 163.748 256.027 163.748Z"
      fill="currentColor"
    />
  </svg>
);

export default EyeOnSvg;
