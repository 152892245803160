import React from 'react';

export const FilterSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4C6.448 4 6 4.448 6 5C6 5.552 6.448 6 7 6C7.552 6 8 5.552 8 5C8 4.448 7.552 4 7 4ZM3 4H4.171C4.583 2.835 5.694 2 7 2C8.306 2 9.417 2.835 9.829 4H17C17.552 4 18 4.448 18 5C18 5.552 17.552 6 17 6H9.829C9.417 7.165 8.306 8 7 8C5.694 8 4.583 7.165 4.171 6H3C2.448 6 2 5.552 2 5C2 4.448 2.448 4 3 4V4ZM17 14C17.552 14 18 14.448 18 15C18 15.552 17.552 16 17 16H15.829C15.417 17.165 14.306 18 13 18C11.694 18 10.583 17.165 10.171 16H3C2.448 16 2 15.552 2 15C2 14.448 2.448 14 3 14H10.171C10.583 12.835 11.694 12 13 12C14.306 12 15.417 12.835 15.829 14H17ZM12 15C12 15.552 12.448 16 13 16C13.552 16 14 15.552 14 15C14 14.448 13.552 14 13 14C12.448 14 12 14.448 12 15Z"
      fill="currentColor"
    />
  </svg>
);

export default FilterSvg;
