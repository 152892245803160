import React from 'react';

export const LogoSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="100"
    height="30"
    viewBox="0 0 100 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.2138 18.8018C27.0541 18.8018 28.2608 17.3837 28.2608 15.2723V9.14863C28.2833 8.67176 28.4886 8.22188 28.834 7.89236C29.1794 7.56284 29.6385 7.379 30.1159 7.379C30.5933 7.379 31.0524 7.56284 31.3978 7.89236C31.7432 8.22188 31.9485 8.67176 31.971 9.14863V15.5455C31.971 19.5876 29.2864 22.2421 25.2138 22.2421C21.1413 22.2421 18.457 19.5876 18.457 15.5455V9.14863C18.457 8.66062 18.6509 8.1926 18.996 7.84752C19.341 7.50245 19.8091 7.30859 20.2971 7.30859C20.7851 7.30859 21.2531 7.50245 21.5982 7.84752C21.9433 8.1926 22.1371 8.66062 22.1371 9.14863V15.2723C22.1371 17.3837 23.374 18.8018 25.2138 18.8018Z"
      fill="currentColor"
    />
    <path
      d="M44.6396 8.99676C44.6504 9.23458 44.6105 9.47197 44.5226 9.6932C44.4346 9.91442 44.3006 10.1144 44.1295 10.2799C43.9583 10.4454 43.7539 10.5726 43.5299 10.6531C43.3058 10.7335 43.0672 10.7654 42.8299 10.7466C42.2566 10.7466 41.6231 10.5654 40.7787 10.5654C39.4511 10.5654 38.5766 11.5609 38.5766 13.0994V20.0977C38.5766 20.5857 38.3827 21.0537 38.0376 21.3988C37.6926 21.7438 37.2245 21.9377 36.7365 21.9377C36.2485 21.9377 35.7805 21.7438 35.4354 21.3988C35.0903 21.0537 34.8965 20.5857 34.8965 20.0977V9.14739C34.898 8.67701 35.079 8.22495 35.4025 7.88349C35.726 7.54202 36.1677 7.33691 36.6373 7.31C37.1069 7.28309 37.5691 7.4364 37.9295 7.73867C38.2899 8.04094 38.5213 8.46938 38.5766 8.93651C38.9685 8.34267 39.5017 7.8554 40.1284 7.51844C40.7551 7.18148 41.4556 7.00535 42.1671 7.00586C43.3726 7.00627 44.6396 7.63938 44.6396 8.99676Z"
      fill="currentColor"
    />
    <path
      d="M47.895 22.6169C47.6445 22.6232 47.3954 22.5771 47.1637 22.4815C46.9321 22.386 46.7229 22.2431 46.5497 22.062C46.3765 21.8809 46.243 21.6656 46.1579 21.4299C46.0727 21.1942 46.0377 20.9433 46.0551 20.6933V9.24491C46.044 8.99632 46.0833 8.74803 46.1707 8.51505C46.2582 8.28207 46.3919 8.06923 46.5639 7.88937C46.7358 7.7095 46.9424 7.56636 47.1713 7.46855C47.4001 7.37075 47.6463 7.32031 47.8952 7.32031C48.144 7.32031 48.3903 7.37075 48.6191 7.46855C48.8479 7.56636 49.0546 7.7095 49.2265 7.88937C49.3985 8.06923 49.5322 8.28207 49.6197 8.51505C49.7071 8.74803 49.7464 8.99632 49.7352 9.24491V20.6933C49.7527 20.9433 49.7177 21.1943 49.6325 21.43C49.5473 21.6657 49.4138 21.881 49.2405 22.0621C49.0673 22.2432 48.8581 22.3861 48.6264 22.4817C48.3947 22.5772 48.1455 22.6233 47.895 22.6169Z"
      fill="currentColor"
    />
    <path
      d="M67.3621 14.8954C67.3621 19.5944 64.2255 22.8428 60.4241 22.8428C59.5745 22.8612 58.7322 22.6828 57.9629 22.3217C57.1936 21.9606 56.5183 21.4265 55.9896 20.7612V27.0689C56.0015 27.3199 55.9622 27.5707 55.8744 27.8061C55.7865 28.0415 55.6518 28.2565 55.4783 28.4384C55.3049 28.6202 55.0964 28.7649 54.8654 28.8638C54.6344 28.9626 54.3858 29.0136 54.1345 29.0136C53.8833 29.0136 53.6346 28.9626 53.4036 28.8638C53.1726 28.7649 52.9641 28.6202 52.7907 28.4384C52.6173 28.2565 52.4825 28.0415 52.3947 27.8061C52.3068 27.5707 52.2676 27.3199 52.2794 27.0689V9.1576C52.2632 8.67545 52.4348 8.20582 52.7582 7.84782C53.0816 7.48982 53.5314 7.27143 54.0127 7.23871C54.494 7.206 54.9692 7.36152 55.338 7.67248C55.7069 7.98343 55.9405 8.42551 55.9896 8.90542C56.5246 8.25366 57.2036 7.73499 57.9731 7.39023C58.7426 7.04547 59.5816 6.88404 60.4241 6.91866C64.4059 6.91659 67.3621 10.354 67.3621 14.8954ZM63.6515 14.9268C63.6515 12.4671 62.0531 10.5108 59.8207 10.5108C57.6185 10.5108 55.9896 12.4662 55.9896 14.9268C55.9896 17.3238 57.6185 19.2474 59.8207 19.2474C62.0531 19.2474 63.6515 17.3238 63.6515 14.9268Z"
      fill="currentColor"
    />
    <path
      d="M76.2301 6.91675C77.7417 6.89571 79.2253 7.32491 80.4922 8.1497C81.759 8.9745 82.7519 10.1576 83.3442 11.5484C83.9366 12.9392 84.1017 14.4748 83.8186 15.9598C83.5355 17.4448 82.817 18.8119 81.7545 19.8872C80.6919 20.9625 79.3335 21.6974 77.852 21.9982C76.3705 22.2991 74.8331 22.1523 73.4353 21.5766C72.0375 21.0009 70.8426 20.0223 70.0027 18.7654C69.1628 17.5085 68.7159 16.0301 68.7189 14.5183C68.707 12.5143 69.4917 10.5876 70.9003 9.16209C72.3089 7.73654 74.2261 6.92887 76.2301 6.91675ZM76.2602 10.3558C74.0279 10.3558 72.399 12.1957 72.399 14.5183C72.399 16.841 74.0279 18.7114 76.2602 18.7114C78.4624 18.7114 80.0612 16.841 80.0612 14.5183C80.0612 12.1957 78.4624 10.3558 76.2602 10.3558Z"
      fill="currentColor"
    />
    <path
      d="M99.9974 2.75599V19.9802C100.009 20.2238 99.9731 20.4674 99.8909 20.697C99.8087 20.9266 99.682 21.1378 99.5182 21.3185C99.3543 21.4991 99.1565 21.6458 98.936 21.75C98.7154 21.8542 98.4765 21.914 98.2329 21.9259C97.9893 21.9378 97.7458 21.9015 97.5161 21.8193C97.2865 21.7371 97.0753 21.6104 96.8946 21.4466C96.714 21.2827 96.5673 21.0849 96.4631 20.8644C96.3589 20.6439 96.2991 20.405 96.2872 20.1613C95.753 20.8064 95.0787 21.321 94.3155 21.6661C93.5523 22.0111 92.7204 22.1773 91.8833 22.1522C88.0521 22.1522 84.9453 19.0454 84.9453 14.5205C84.9453 10.1768 87.871 6.91892 91.8833 6.91892C92.709 6.89281 93.5305 7.04652 94.291 7.36941C95.0514 7.6923 95.7326 8.17664 96.2872 8.78888V2.75599C96.3097 2.27912 96.515 1.82921 96.8604 1.49969C97.2059 1.17017 97.6649 0.986328 98.1423 0.986328C98.6197 0.986328 99.0788 1.17017 99.4242 1.49969C99.7697 1.82921 99.9749 2.27912 99.9974 2.75599ZM96.2872 14.5205C96.2872 12.1978 94.6884 10.358 92.4867 10.358C90.2543 10.358 88.6254 12.1978 88.6254 14.5205C88.6254 16.8432 90.2543 18.7136 92.4867 18.7136C94.6884 18.7119 96.2872 16.8416 96.2872 14.518V14.5205Z"
      fill="currentColor"
    />
    <path
      d="M10.2561 2.11914C13.8759 2.11914 17.0434 3.77821 17.0434 5.61846C17.0434 6.73276 16.2287 7.27754 15.294 7.27754C13.9061 7.27754 12.9106 5.8599 10.0753 5.8599C6.27434 5.8599 4.0119 8.69517 4.0119 12.1945C4.0119 15.6633 6.27434 18.4676 10.0753 18.4676C12.9106 18.4676 13.9061 17.0801 15.294 17.0801C16.2287 17.0801 17.0434 17.5927 17.0434 18.7387C17.0434 20.5489 13.8759 22.2381 10.2561 22.2381C3.85919 22.2381 8.26669e-07 17.7132 8.26669e-07 12.1933C-0.00206269 6.61391 3.85919 2.11914 10.2561 2.11914Z"
      fill="currentColor"
    />
    <path
      d="M8.35197 14.0203C9.36831 14.0203 10.1922 13.1964 10.1922 12.1801C10.1922 11.1638 9.36831 10.3398 8.35197 10.3398C7.33563 10.3398 6.51172 11.1638 6.51172 12.1801C6.51172 13.1964 7.33563 14.0203 8.35197 14.0203Z"
      fill="currentColor"
    />
    <path
      d="M13.6938 14.0203C14.7101 14.0203 15.534 13.1964 15.534 12.1801C15.534 11.1638 14.7101 10.3398 13.6938 10.3398C12.6774 10.3398 11.8535 11.1638 11.8535 12.1801C11.8535 13.1964 12.6774 14.0203 13.6938 14.0203Z"
      fill="currentColor"
    />
  </svg>
);
