import React from 'react';

export const ArrowLeftSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17 12.7101L13.41 16.9501C13.503 17.0438 13.6136 17.1182 13.7354 17.1689C13.8573 17.2197 13.988 17.2458 14.12 17.2458C14.252 17.2458 14.3827 17.2197 14.5046 17.1689C14.6264 17.1182 14.737 17.0438 14.83 16.9501C15.0162 16.7627 15.1208 16.5092 15.1208 16.2451C15.1208 15.9809 15.0162 15.7274 14.83 15.5401L11.29 12.0001L14.83 8.46005C15.0162 8.27269 15.1208 8.01924 15.1208 7.75505C15.1208 7.49086 15.0162 7.23741 14.83 7.05005C14.7366 6.95737 14.6257 6.88404 14.5039 6.83428C14.3821 6.78452 14.2516 6.75929 14.12 6.76005C13.9884 6.75929 13.8579 6.78452 13.7361 6.83428C13.6143 6.88404 13.5034 6.95737 13.41 7.05005L9.17 11.2901C9.07627 11.383 9.00187 11.4936 8.95111 11.6155C8.90034 11.7373 8.8742 11.868 8.8742 12.0001C8.8742 12.1321 8.90034 12.2628 8.95111 12.3846C9.00187 12.5065 9.07627 12.6171 9.17 12.7101Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowLeftSvg;
