import React from 'react';

export const QuestionMarkSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_13066_31972)">
      <path
        d="M10 0C4.47301 0 0 4.4725 0 10C0 15.5269 4.4725 20 10 20C15.527 20 20 15.5275 20 10C20 4.47301 15.5275 0 10 0ZM10 18.6046C5.25539 18.6046 1.39535 14.7446 1.39535 10C1.39535 5.25535 5.25539 1.39535 10 1.39535C14.7446 1.39535 18.6046 5.25535 18.6046 10C18.6046 14.7446 14.7446 18.6046 10 18.6046Z"
        fill="currentColor"
      />
      <path
        d="M9.7041 12.6533C9.15124 12.6533 8.7037 13.114 8.7037 13.6668C8.7037 14.2066 9.13808 14.6804 9.7041 14.6804C10.2701 14.6804 10.7176 14.2066 10.7176 13.6668C10.7176 13.114 10.2569 12.6533 9.7041 12.6533Z"
        fill="currentColor"
      />
      <path
        d="M9.8752 4.97925C8.09817 4.97925 7.28207 6.03233 7.28207 6.74311C7.28207 7.25647 7.71645 7.49343 8.07184 7.49343C8.78266 7.49343 8.49309 6.47983 9.83571 6.47983C10.4938 6.47983 11.0204 6.76944 11.0204 7.37495C11.0204 8.08573 10.2832 8.49378 9.84887 8.86233C9.46711 9.19136 8.96696 9.73108 8.96696 10.8631C8.96696 11.5476 9.15125 11.745 9.6909 11.745C10.3359 11.745 10.4675 11.4555 10.4675 11.2053C10.4675 10.5208 10.4807 10.126 11.2047 9.55995C11.56 9.28354 12.6789 8.38843 12.6789 7.15112C12.6789 5.91382 11.56 4.97925 9.8752 4.97925Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default QuestionMarkSvg;
