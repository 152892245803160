import React from 'react';

export const CheckSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.55613 0.337386L4.59395 5.29957L2.44426 3.14989C2.26745 2.97911 2.03063 2.88462 1.78482 2.88675C1.53901 2.88889 1.30388 2.98749 1.13006 3.16131C0.956236 3.33513 0.85764 3.57026 0.855504 3.81607C0.853368 4.06188 0.947863 4.2987 1.11864 4.47551L3.93114 7.28801C4.10694 7.46377 4.34536 7.5625 4.59395 7.5625C4.84254 7.5625 5.08095 7.46377 5.25676 7.28801L10.8818 1.66301C11.0525 1.4862 11.147 1.24938 11.1449 1.00357C11.1428 0.757763 11.0442 0.522626 10.8703 0.348806C10.6965 0.174986 10.4614 0.0763902 10.2156 0.0742542C9.96976 0.0721182 9.73295 0.166613 9.55613 0.337386Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckSvg;
