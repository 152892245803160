import React from 'react';

export const ArrowDownSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_22886_2215)">
      <path
        d="M2.14552 12.6687C2.14775 12.671 2.15002 12.6732 2.15229 12.6755L9.14433 19.6767C9.19988 19.732 9.26127 19.7811 9.32738 19.8231L9.52871 19.933L9.66598 19.9787H9.76667C9.92094 20.0071 10.0791 20.0071 10.2334 19.9787H10.3249H10.4347L10.5903 19.8964C10.677 19.8493 10.7571 19.7908 10.8283 19.7225L17.8478 12.6755C18.3178 12.2092 18.3209 11.4501 17.8546 10.98C17.8523 10.9778 17.8501 10.9755 17.8478 10.9733C17.374 10.5212 16.6285 10.5212 16.1547 10.9733L11.9723 15.1465C11.7918 15.3234 11.5021 15.3206 11.3252 15.1401C11.2428 15.0562 11.196 14.9438 11.1944 14.8262L11.1944 1.19901C11.1944 0.536854 10.6577 5.23367e-05 9.99554 9.43708e-06C9.33338 -3.34625e-05 8.79662 0.536682 8.79654 1.19884V1.19901L8.79654 14.8262C8.79298 15.0789 8.58526 15.2809 8.33258 15.2773C8.21503 15.2757 8.1026 15.2288 8.01864 15.1465L3.85451 10.9733C3.37948 10.5153 2.62728 10.5153 2.15225 10.9733C1.6822 11.4396 1.67916 12.1987 2.14552 12.6687Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default ArrowDownSvg;
