import React from 'react';

export const ExclamationMarkSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.47254 0 0 4.47293 0 10C0 15.5275 4.47293 20 10 20C15.5275 20 20 15.5271 20 10C20 4.47254 15.5271 0 10 0ZM10 18.4375C5.33621 18.4375 1.5625 14.6635 1.5625 10C1.5625 5.33621 5.33652 1.5625 10 1.5625C14.6638 1.5625 18.4375 5.33652 18.4375 10C18.4375 14.6638 14.6635 18.4375 10 18.4375Z"
      fill="currentColor"
    />
    <path
      d="M10 5.03418C9.56852 5.03418 9.21875 5.38395 9.21875 5.81543V10.8464C9.21875 11.2779 9.56852 11.6277 10 11.6277C10.4315 11.6277 10.7812 11.2779 10.7812 10.8464V5.81543C10.7812 5.38395 10.4315 5.03418 10 5.03418Z"
      fill="currentColor"
    />
    <path
      d="M10 14.6934C10.5825 14.6934 11.0547 14.2212 11.0547 13.6387C11.0547 13.0562 10.5825 12.584 10 12.584C9.41751 12.584 8.94531 13.0562 8.94531 13.6387C8.94531 14.2212 9.41751 14.6934 10 14.6934Z"
      fill="currentColor"
    />
  </svg>
);

export default ExclamationMarkSvg;
