import React from 'react';

export const ArrowUpSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_22886_2207)">
      <path
        d="M17.8545 7.33127C17.8523 7.329 17.85 7.32677 17.8477 7.32449L10.8557 0.323281C10.8001 0.268026 10.7387 0.218949 10.6726 0.176864L10.4713 0.0670413L10.334 0.0212675H10.2333C10.0791 -0.00708916 9.92089 -0.00708916 9.76658 0.0212675H9.67508H9.56525L9.40966 0.103635C9.32296 0.150738 9.24291 0.209211 9.17169 0.277507L2.15221 7.32449C1.68215 7.79081 1.67911 8.54992 2.14543 9.01997C2.14766 9.02224 2.14993 9.02448 2.15221 9.02675C2.62603 9.47882 3.37145 9.47882 3.84532 9.02675L8.02773 4.85348C8.20817 4.67656 8.49791 4.67939 8.67483 4.85987C8.75715 4.94382 8.804 5.05622 8.80563 5.17381V18.801C8.80559 19.4631 9.34231 19.9999 10.0045 20C10.6666 20 11.2034 19.4633 11.2035 18.8012V18.801V5.17381C11.207 4.92113 11.4147 4.71912 11.6674 4.72268C11.785 4.72435 11.8974 4.77115 11.9814 4.85348L16.1455 9.02675C16.6205 9.48466 17.3727 9.48466 17.8477 9.02675C18.3178 8.56043 18.3208 7.80132 17.8545 7.33127Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default ArrowUpSvg;
