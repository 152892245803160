import { Button as AntButton } from 'antd';
import { ButtonHTMLType } from 'antd/lib/button/button';
import React from 'react';
import styled, { css } from 'styled-components';

import theme from '../../assets/theme';

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger'
  | 'ghost'
  | 'linkStyle'
  | 'white';
interface ButtonProps {
  type?: ButtonType;
  disabled?: boolean;
  width?: string;
  height?: string;
  minWidth?: string;
  icon?: React.ReactElement;
  htmlType?: ButtonHTMLType;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  styles?: React.CSSProperties;
  loading?: boolean;
  notext?: boolean;
  className?: string;
  children?: React.ReactNode;
  onMouseEnter?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMouseDown?: (e: any) => void;
  onMouseLeave?: () => void;
  id?: string;
}

export const Button: React.FC<ButtonProps> = props => {
  const {
    styles,
    onClick,
    type,
    className,
    width,
    minWidth,
    height,
    disabled,
    icon,
    htmlType,
    loading,
    children,
    notext,
    onMouseEnter,
    onMouseDown,
    onMouseLeave,
    id,
    ...rest
  } = props;

  const typeValue = type === undefined ? 'primary' : type;

  return (
    <StyledButton
      className={className}
      onMouseDown={onMouseDown}
      style={styles}
      $styleType={typeValue}
      $customWidth={width}
      $customMinWidth={minWidth}
      $customHeight={height}
      disabled={disabled}
      icon={icon}
      $notext={notext}
      onClick={onClick}
      htmlType={htmlType}
      loading={loading}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      id={id}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

interface StyledButtonProps {
  $styleType: ButtonType;
  $customWidth?: string;
  $customHeight?: string;
  $customMinWidth?: string;
  $notext?: boolean;
}

const StyledButton = styled(AntButton)<StyledButtonProps>`
  border: none;
  border-radius: 5px;
  font-family: 'Nunito';
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  height: auto;
  line-height: 22px;
  padding: 11.5px 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }

  /** Disabling Animation on Click */
  &[ant-click-animating-without-extra-node]:after {
    animation: none !important;

    -moz-animation: none !important;
    -ms-animation: none !important;
    -o-animation: none !important;
    -webkit-animation: none !important;
  }

  ${props =>
    props.$customWidth !== undefined &&
    css`
      width: ${props.$customWidth};
    `}

  ${props =>
    props.$customWidth !== undefined &&
    css`
      min-width: ${props.$customMinWidth};
    `}

  ${props =>
    props.$styleType === 'primary' &&
    css`
      background: ${theme.colors.green};
      color: #ffffff;
      transition: 0.4s;

      &:focus {
        background: #157271;
        border-color: #ffffff;
        color: #ffffff;
      }

      &:hover {
        background: #14857a;
        border-color: #ffffff;
        color: #ffffff;
      }

      &:active {
        background: #0e4b4b;
        border-color: #ffffff;
        color: #ffffff;
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus,
      &[disabled]:active {
        background: ${theme.colors.text.medium};
        border-color: #ffffff;
        color: #ffffff;
      }
    `}
  ${props =>
    props.$styleType === 'white' &&
    css`
      background: ${theme.colors.background};
      color: ${theme.colors.black};
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      box-shadow: ${theme.effects.floatingCreatorElementShadow};

      &:focus {
        background: rgba(255, 255, 255, 0.8);
        border-color: rgba(255, 255, 255, 0.8);
        box-shadow: ${theme.effects.floatingCreatorElementShadowHovered};
        color: ${theme.colors.black};
      }

      &:hover {
        background: #ffffff;
        border-color: #ffffff;
        box-shadow: ${theme.effects.floatingCreatorElementShadowHovered};
        color: ${theme.colors.black};
      }

      &:active {
        background: rgba(255, 255, 255, 0.8);
        border-color: rgba(255, 255, 255, 0.8);
        box-shadow: ${theme.effects.floatingCreatorElementShadowHovered};
        color: ${theme.colors.black};
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus,
      &[disabled]:active {
        background: rgba(255, 255, 255, 0.5);
        border-color: #ffffff;
        color: ${theme.colors.black};
      }
    `}

  ${props =>
    props.$styleType === 'secondary' &&
    css`
      background: rgba(51, 160, 221, 0.1);
      color: #33a0dd;

      &:focus {
        background: rgba(51, 160, 221, 0.1);
        border-color: #ffffff;
        color: #33a0dd;
      }

      &:hover {
        background: rgba(51, 160, 221, 0.2);
        border-color: #ffffff;
        color: #33a0dd;
      }

      &:active {
        background: rgba(51, 160, 221, 0.25);
        border-color: #ffffff;
        color: #33a0dd;
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus,
      &[disabled]:active {
        background: rgba(51, 160, 221, 0.05);
        border-color: #ffffff;
        color: #33a0dd;
        opacity: 0.6;
      }
    `}

  ${props =>
    props.$styleType === 'tertiary' &&
    css`
      background: transparent;
      border: 1px solid ${theme.colors.green};
      box-sizing: border-box;
      color: ${theme.colors.green};
      padding: 10.5px 24px;

      &:focus {
        background: rgba(0, 0, 0, 0.1);
        color: ${theme.colors.green};
        border-color: ${theme.colors.green};
      }

      &:hover {
        background: rgba(0, 0, 0, 0.1);
        border-color: rgba(19, 153, 130, 0.7);
        color: ${theme.colors.green};
      }

      &:active {
        background: rgba(52, 160, 123, 0.05);
        border-color: ${theme.colors.green};
        color: ${theme.colors.green};
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus,
      &[disabled]:active {
        background: #ffffff;
        border-color: rgb(19, 153, 130, 0.5);
        color: ${theme.colors.lightGreen};
      }
    `}
  
    ${props =>
      props.$styleType === 'danger' &&
      css`
        // background: rgba(235, 87, 87, 0.07);
        background: #c44137;
        color: ${theme.colors.white};
        transition: 0.4s;

        &:focus {
          // background: rgba(235, 87, 87, 0.07);
          // color: #eb5757;
          border-color: #c44137;
          background: #c44137;
          color: ${theme.colors.white};
        }

        &:hover {
          // background: rgba(235, 87, 87, 0.07);
          // border-color: #eb5757;
          // color: #eb5757;
          border-color: #c44137;
          background: #c44137;
          color: ${theme.colors.white};
        }

        &:active {
          // background: rgba(235, 87, 87, 0.07);
          // border-color: #eb5757;
          // color: #eb5757;
          border-color: #c44137;
          background: #c44137;
          color: ${theme.colors.white};
        }

        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus,
        &[disabled]:active {
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.8),
              rgba(255, 255, 255, 0.8)
            ),
            rgba(235, 87, 87, 0.07);
          border-color: #ffffff;
          color: #c44137;
        }
      `}
    ${props =>
      props.$styleType === 'ghost' &&
      css`
        background: transparent;
        border: 1px solid ${theme.colors.black};
        box-sizing: border-box;
        color: ${theme.colors.black};
        padding: 10.5px 24px;

        &:focus {
          background: transparent;
          color: ${theme.colors.black};
          border-color: ${theme.colors.black};
        }

        &:hover {
          background: transparent;
          background-color: rgba(0, 0, 0, 0.1);
          border-color: ${theme.colors.black};
          color: ${theme.colors.black};
        }

        &:active {
          background: transparent;
          border-color: ${theme.colors.black};
          color: ${theme.colors.black};
        }

        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus,
        &[disabled]:active {
          background: #ffffff;
          border-color: ${theme.colors.greyLight};
          color: ${theme.colors.greyLight};
        }
      `}

      & .ant-btn-loading-icon {
        margin-right: 5px;
      }
    ${props =>
      props.$styleType === 'linkStyle' &&
      css`
        background: transparent;
        border: none;
        box-sizing: border-box;
        color: ${theme.colors.black};
        padding: 0px;
        box-shadow: none;
        text-decoration: underline;

        &:focus {
          background: transparent;
          color: ${theme.colors.black};
          border-color: ${theme.colors.black};
          text-decoration: underline;
        }

        &:hover {
          background: transparent;
          color: ${theme.colors.black};
          text-decoration: none;
        }

        &:active {
          background: transparent;
          border-color: ${theme.colors.black};
          color: ${theme.colors.black};
          text-decoration: underline;
        }

        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus,
        &[disabled]:active {
          background: #ffffff;
          color: ${theme.colors.greyLight};
        }
      `}

      & .ant-btn-loading-icon {
        margin-right: 5px;
      }

    ${props =>
      props.icon &&
      css`
        padding: 10px 20px;

        & > svg {
          vertical-align: middle;
        }
      `}

      ${({ $notext }) =>
        $notext &&
        css`
          padding: 10px 5px;
          svg {
            margin-right: 0px;
          }
        `};

      ${props =>
        props.$customHeight !== undefined &&
        css`
          height: ${props.$customHeight};
          padding-top: 0px;
          padding-bottom: 0px;
        `}
`;
