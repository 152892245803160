import React from 'react';

export const CrossSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
    <path
      id="close"
      data-name="close"
      d="M18.439,20.561,10.5,12.621,2.561,20.561A1.5,1.5,0,1,1,.439,18.439L8.379,10.5.439,2.561A1.5,1.5,0,0,1,2.561.439L10.5,8.379,18.439.439a1.5,1.5,0,1,1,2.121,2.121L12.621,10.5l7.939,7.939a1.5,1.5,0,0,1-2.121,2.121Z"
      transform="translate(0 0)"
      fill="currentColor"
    />
  </svg>
);

export default CrossSvg;
